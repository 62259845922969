<template>
  <main class="kb-main" id="kb-myclass-hrdcloud">
    <lxp-main-header :show-back="true" :show-title="true" title="부점장 HRD 클라우드">
      <template v-slot:action>
        <!-- main-header > header-snb -->
        <HrdCloudCategory />
        <!-- //main-header -->
      </template>
    </lxp-main-header>
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="hrdcloud-list-container">
        <!-- list-top -->
        <div class="list-top">
          <div class="top-column">
            <!-- list-top > list-sort -->
            <div class="list-sort">
              <div class="sort">
                <!-- kb-form-dropdown -->
                <div class="kb-form-dropdown">
                  <button class="kb-form-dropdown-btn"><strong class="text">최신순</strong><i class="icon-arrow"></i></button>
                  <div class="kb-form-dropdown-options">
                    <div class="dropdown-option">
                      <ul class="dropdown-option-list">
                        <li class="dropdown-option-item"><a href="#" class="dropdown-option-link"><span class="dropdown-option-text">옵션1</span></a></li>
                        <li class="dropdown-option-item"><a href="#" class="dropdown-option-link is-active"><span class="dropdown-option-text">옵션2</span></a></li>
                        <li class="dropdown-option-item"><a href="#" class="dropdown-option-link"><span class="dropdown-option-text">옵션3</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- //kb-form-dropdown -->
              </div>
            </div>
          </div>
          <div class="top-column">
            <div class="kb-form-search">
              <div class="kb-form-search-field">
                <input type="text" class="kb-form-search-input" placeholder="HRD 클라우드 검색" />
                <button class="kb-form-search-btn"><i class="icon-search">검색</i></button>
              </div>
            </div>
          </div>
        </div>
        <!-- //list-top -->
        <div class="hrdcloud-list-wrapper">
          <ul class="hrdcloud-list" data-grid="4">
            <li v-for="(item, idx) in items" class="hrdcloud-item" :key="idx">
              <article class="hrdcloud-card">
                <router-link class="hrdcloud-image" :to="{ name: 'HrdCloudDetail' }">
                  <div class="image">
                    <a href="#" class="image-link">
                      <img :src="item.src" :alt="item.title" />
                    </a>
                  </div>
                  <div class="badges">
                    <div :class="[item.badge === '시리즈' ? 'badge-active' : 'badge-inactive' ]">
                      <span class="text">{{ item.badge }}</span>
                      <i class="icon-check"></i>
                    </div>
                  </div>
                </router-link>
                <div class="hrdcloud-content">
                  <div class="content-category">
                    <span class="text">{{ item.category }}</span>
                  </div>
                  <div class="content-title">
                    <h5 class="title"><router-link :to="{ name: 'HrdCloudDetail' }" class="title-link">{{ item.title }}</router-link></h5>
                  </div>
                  <div class="content-info">
                    <div class="info">
                      <i class="icon-views"></i>
                      <span class="text">{{ item.view }}</span>
                    </div>
                    <div class="info">
                      <i class="icon-like"></i>
                      <span class="text">{{ item.like }}</span>
                    </div>
                  </div>
                  <div class="content-meta">
                    <span class="text">{{ item.regDt }}</span>
                    <span class="text">{{ item.regTime }}</span>
                  </div>
                  <div class="content-interest">
                    <button class="kb-btn-interest" data-content="담기">
                      <i class="icon-interest"></i>
                    </button>
                  </div>
                </div>
              </article>
            </li>
          </ul>
          <nav class="kb-pagination">
            <ol class="page-list">
              <li class="page-item page-prev"><a href="#" class="page-link"><i class="icon-page-prev"></i></a></li>
              <li class="page-item"><a href="#" class="page-link">1</a></li>
              <li class="page-item is-active"><span class="page-link">2</span></li>
              <li class="page-item"><a href="#" class="page-link">3</a></li>
              <li class="page-item"><a href="#" class="page-link">4</a></li>
              <li class="page-item"><a href="#" class="page-link">5</a></li>
              <li class="page-item page-next"><a href="#" class="page-link"><i class="icon-page-next"></i></a></li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {reactive, ref} from 'vue';
import HrdCloudCategory from '@/components/hrdcloud/HrdCloudCategory';

export default {
  name: 'HrdCloudHome',
  components: {HrdCloudCategory, LxpMainHeader},
  setup(){

    const items = ref([
      {
        src: require('../../../assets/lxp/images/@tmp/@tmp_hrdcloud_01.jpg'),
        badge: '완료',
        category: '집합연수',
        title: 'WM주간투자전략 화상회의',
        view: 355,
        like: 12,
        regDt: '2021.11.22',
        regTime: '02:31'
      },
      {
        src: require('../../../assets/lxp/images/@tmp/@tmp_hrdcloud_02.jpg'),
        badge: '시리즈',
        category: '집합연수',
        title: 'WM주간투자전략 화상회의',
        view: 4252,
        like: 567,
        regDt: '2021.11.22',
        regTime: '02:31'
      },
      {
        src: require('../../../assets/lxp/images/@tmp/@tmp_hrdcloud_03.jpg'),
        badge: '완료',
        category: '집합연수',
        title: 'WM주간투자전략 화상회의',
        view: 9840,
        like: 1082,
        regDt: '2021.11.22',
        regTime: '02:31'
      },
      {
        src: require('../../../assets/lxp/images/@tmp/@tmp_hrdcloud_04.jpg'),
        badge: '시리즈',
        category: '집합연수',
        title: 'WM주간투자전략 화상회의',
        view: 50,
        like: 12,
        regDt: '2021.11.22',
        regTime: '02:31'
      },
      {
        src: require('../../../assets/lxp/images/@tmp/@tmp_hrdcloud_05.jpg'),
        badge: '완료',
        category: '집합연수',
        title: 'WM주간투자전략 화상회의',
        view: 4453,
        like: 347,
        regDt: '2021.11.22',
        regTime: '02:31'
      },
      {
        src: require('../../../assets/lxp/images/@tmp/@tmp_hrdcloud_06.jpg'),
        badge: '완료',
        category: '집합연수',
        title: 'WM주간투자전략 화상회의',
        view: 753,
        like: 257,
        regDt: '2021.11.22',
        regTime: '02:31'
      },
      {
        src: require('../../../assets/lxp/images/@tmp/@tmp_hrdcloud_07.jpg'),
        badge: '완료',
        category: '집합연수',
        title: 'WM주간투자전략 화상회의',
        view: 289,
        like: 61,
        regDt: '2021.11.22',
        regTime: '02:31'
      },
      {
        src: require('../../../assets/lxp/images/@tmp/@tmp_hrdcloud_08.jpg'),
        badge: '완료',
        category: '집합연수',
        title: 'WM주간투자전략 화상회의',
        view: 738,
        like: 35,
        regDt: '2021.11.22',
        regTime: '02:31'
      },
    ]);

    const paging = reactive({
      pageNo: 1,
      pageSize: 12,
      totalCount: 145
    });

    return {
      items,
      paging
    }

  }
};
</script>

<style scoped>

</style>